<template>
	<div>
		<b-modal
			id="modal-upload-bill"
			cancel-variant="outline-secondary"
			:ok-title="$t('Submit')"
			:cancel-title="$t('Close')"
			:ok-variant="okVariant"
			centered
			:title="title"
			:modal-class="modalClass"
			scrollable
			@ok="confirmUpload"
			@hidden="resetForm"
		>
			<!-- form -->
			<validation-observer
				#default="{ handleSubmit }"
				ref="refFormObserver"
			>
				<b-form
					@submit.prevent="handleSubmit(validationForm)"
					@reset.prevent="resetForm"
				>
					<b-row>
						<!-- Transaction Id -->
						<b-col
							cols="12"
						>
							<b-form-group
								:label="$t('Transaction id')"
								label-for="transactionId"
							>
								<b-form-input
									id="name"
									v-model="transactionId"
									trim
									disabled
								/>
							</b-form-group>
						</b-col>
						<!-- Bill -->
						<b-col
							cols="12"
						>
							<b-form-group
								label-for="attachments"
							>
								<label for=""><span class="text-danger">*</span> {{ $t('Bills') }} <span class="text-secondary">(Không > 10Mb)</span></label>
								<validation-provider
									#default="{ errors }"
									name="attachments"
									rules="required|size:10240"
								>
									<b-form-file
										v-model="attachments"
										multiple
										input-id="attachments"
										accept="image/jpeg,image/png,image/gif,image/svg,image/jpg"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
								
							</b-form-group>
							<b-button
								v-show="attachments.length != 0"
								variant="outline-danger"
								class="btn-sm btn-icon"
								@click="attachments = []"
							>
								<feather-icon icon="XIcon"/>
							</b-button>
						</b-col>
					</b-row>
				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import {
	BLink, BRow, BCol, BForm, BFormFile, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BBadge, BFormInvalidFeedback,
} from 'bootstrap-vue'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { numberFormat, resolveCurrency } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import useUploadBill from '@/views/payments/upload-bill/useUploadBill'

export default {
	components: {
		BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea, BBadge, BFormFile,
		BInputGroupAppend,
		BFormInvalidFeedback,
		ValidationProvider,
		ValidationObserver,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		reference: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			title: this.$t('Upload bill'),
			modalClass: 'modal-primary',
			okVariant: 'primary',
            transactionId: null,
			attachments: [],
		}
	},
	watch: {
		reference: {
			deep: true,
			handler(newVal) {
				if (newVal) {
					this.transactionId = newVal
				}
			}
		},
	},
	methods: {
		validationForm() {
			this.$refs.refFormObserver.validate().then(success => {
				if (success) {
					this.uploadBill({
                        ref: this.reference,
                        attachments: this.attachments
                    })
                    this.$nextTick(() => {
                        setTimeout(() => {
                            this.$bvModal.hide('modal-upload-bill')
                            this.resetForm()
                        }, '500')
                    })
				}
			})
		},
		confirmUpload(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationForm()
		},
		resetForm() {
			this.$emit('update:reference', null)
            this.transactionId = null
			this.attachments = []
		},
	},
	setup() {
		const { uploadBill } = useUploadBill()
		return {
			uploadBill
		}
	},
}
</script>
